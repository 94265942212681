import React, { cloneElement, useEffect, useState } from "react";
import { Row, Col, Form, message } from 'antd';
import { createProspects } from "../../service/letsChat";

type FieldEnum = 'string' | 'enum' | 'boolean' | 'number' | 'email';
type FieldTypes = {
    type: FieldEnum,
    name: string
    id: string
    options?: FieldEnum extends 'enum' ? any : never;
    span?: number
    required?: boolean
}

const fields: FieldTypes[] = [
    {
        name: 'First Name',
        id: 'firstName',
        type: 'string',
        span: 12,
        required: true
    },
    {
        name: 'Last Name',
        id: 'lastName',
        type: 'string',
        span: 12,
        required: true
    },
    {
        name: 'Company',
        id: 'company',
        type: 'string',
        required: true
    },
    {
        name: 'Work email',
        id: 'email',
        type: 'email',
        required: true
    },
    {
        name: 'Company size (estimated number of employees)',
        id: 'size',
        type: 'number',
        required: true
    },
    {
        name: 'What do you hope to achieve with Unizo?',
        id: 'AchiveWithUnizo',
        type: 'string',
        required: true
    },
    {
        name: 'What integrations are you interested in?',
        id: 'integrationsInterested',
        type: 'string',
        required: true
    }
]

const fieldMap = {
    'string': <input type="input" className="input w-full" />,
    'enum': <input />,
    'boolean': <input type='radio' />,
    'number': <input type='number' className="input w-full" />,
    'email': <input type='email' className="input w-full" />,
}

declare global {
    interface Window {
        onReCaptcha: (token: string) => void;
    }
}

const SuccessMessage = () => {
    return (
        <div className="flex flex-col items-center justify-center gap-2.5 mx-auto p-6">
            <div className="bg-green-500 justify-center flex items-center flex-shrink-0 text-white rounded-full p-2 w-[49px] h-[49px]">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 13l4 4L19 7"
                    />
                </svg>
            </div>
            <h1 className="text-5xl font-bold text-center m-3">Thank you</h1>
            <div className="text-center">
                <p className="text-lg text-gray-800 mb-1">Thank you! Your submission has been received.</p>
                <p className="text-lg text-gray-800 mb-0">We will be in touch you shortly</p>
            </div>
        </div>
    );
};

const SectionSideForm = ({ className }) => {
    const [form] = Form.useForm();
    const [isReCaptchaVerified, setReCaptchaVerified] = useState(false);
    const [messageApi, contextHolder] = message.useMessage()
    const [submitting, setLoading] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);

    let content: string;
    let code: number;
    let type: 'error' | 'success' = 'success';

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        window.onReCaptcha = (token) => {
            if (token) {
                setReCaptchaVerified(true);
            }
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const onSubmit = async () => {
        try {
            const formDetails = await form.validateFields();
            const payload = {
                type: 'DEMO_REQUEST',
                name: formDetails.company,
                description: formDetails.description,
                size: formDetails.size,
                industry: formDetails.industry,
                integrationPreferences: formDetails.integrationsInterested,
                objectives: formDetails.AchiveWithUnizo,
                contact: {
                    firstName: formDetails.firstName,
                    lastName: formDetails.lastName,
                    designation: formDetails.designation,
                    emails: [
                        {
                            type: 'WORK',
                            email: formDetails.email
                        }
                    ]
                }
            };
            try {
                setLoading(true);
                await createProspects(payload);
                content = 'Requested successfully.';
                form.resetFields();
                setSubmitted(true);
            } catch (error) {
                content = error?.response?.data || 'Something went wrong!';
                code = error?.response?.status || 500;

                if (Array.isArray(content) && content.length > 0) {
                    content = content[0].errorMessage;
                }
                type = 'error';
            } finally {
                messageApi.open({
                    type,
                    content: `${code ? code : ''} ${content}`,
                });
                setTimeout(setLoading, 500, false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className={className}>
            <div>
                {contextHolder}
                {!submitted ? (
                    <Form form={form} className="letsChartFormMain">
                        <Row gutter={[25, 0]}>
                            {fields.map((item) => {
                                const { span = 24, id } = item;
                                return (
                                    <Col span={span} key={id}>
                                        <FormItem {...item} />
                                    </Col>
                                );
                            })}
                        </Row>
                        <div className="richtext">
                            By submitting this form, you agree that we may process your data as described in our
                            privacy policy.
                        </div>

                        <div className="g-recaptcha" data-sitekey="6Ldo66EoAAAAAObxMF2AFwa_IzdVrgfNLepixbl9"
                            data-callback="onReCaptcha"></div>

                        <div className="form-action py-5">
                            <button onClick={onSubmit} type="submit" className="m-auto btn btn-lg btn-primary w-full sspart" disabled={!isReCaptchaVerified}>{submitting ? 'Submitting...' : 'Submit'}</button>
                        </div>
                    </Form>) : (
                    <SuccessMessage />
                )}

            </div>
        </div>
    );
}

type FormItemProps = FieldTypes;

const FormItem = (props: FormItemProps) => {
    const { name, id, type, required = false } = props;
    return (
        <Form.Item key={id} name={id} rules={[{ required }]}>
            <div className="form-item">
                <label htmlFor={id}>{name}</label>
                {cloneElement(fieldMap[type], {
                    id,
                    required,
                    // placeholder: `Enter ${name}`
                })}
            </div>
        </Form.Item>
    );
}

export default SectionSideForm;
